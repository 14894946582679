import React from 'react';
import { Box } from '@latitude/box';
import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import Layout from '@/components/layout';
import {
  GO_MAC_SITE_URL,
  GO_MAC_TITLE_SUFFIX,
  PRODUCT_NAME
} from '@/utils/constants';
import { AnalyticsContext } from 'latitude-analytics';
import GoMicrositeHeader from '../_go-microsite-header';

const AboutUs = ({ location }) => {
  const [analytics] = React.useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'partner-discount',
    page_pageType: 'form-page',
    page_siteSection: 'information',
    page_siteSubsection: 'credit-cards'
  };

  return (
    <Layout location={location}>
      <Metadata
        title={`Partner discount | ${GO_MAC_TITLE_SUFFIX}`}
        description={`${PRODUCT_NAME} is a product of Latitude Financial Services, a leading consumer finance company in Australia and New Zealand. Find out more."`}
        keywords="interest free, credit card, about us"
        canonical={`${GO_MAC_SITE_URL}${location.pathname}`}
      />
      <Box css="margin-top:50px;">
        <SecondaryHero heading="Partner discount" />
      </Box>

      <GoMicrositeHeader />

      <Box.Section>
        <iframe
          src="https://eform.latitudefinancial.com.au/eform/Page.do?eFormName=gomastercard_annual_fee_waiver_form"
          title="partner-discount-form"
          css="width:100%; height:100vh; border:0;"
        />
      </Box.Section>
    </Layout>
  );
};

export default AboutUs;
